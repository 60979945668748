import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = [ "button", "source" ]

  connect() {
    if (!document.queryCommandSupported("copy")) {
      this.buttonTarget.classList.add("hidden")
    } else {
      tippy(this.buttonTarget, {
        content: "Copy to clipboard",
        animation: 'scale-subtle',
        theme: 'custom'
      })
    }
  }

  copy(event) {
    event.preventDefault()
    this.addToClipboard()
    this.updateTooltip()
  }

  addToClipboard() {
    var input = document.createElement("input")
    input.value = this.valueToCopy()
    document.body.appendChild(input)
    input.select()
    document.execCommand("copy")
    document.body.removeChild(input)
  }

  updateTooltip() {
    this.buttonTarget._tippy.show()
    this.buttonTarget._tippy.setContent("Copied")

    setTimeout(() => this.buttonTarget._tippy.setContent("Copy to clipboard"), 2000)
  }

  valueToCopy() {
    if (this.sourceTarget.tagName == "INPUT") {
      return this.sourceTarget.value
    } else {
      return this.sourceTarget.innerText
    }
  }
}
