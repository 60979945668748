import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="compose"
export default class extends Controller {
  static targets = [ "bcc", "bccCheckbox", "bccInput", "cc", "ccCheckbox", "ccInput" ]

  connect() {
    this.toggleBcc()
    this.toggleCc()
  }

  cc() {
    this.toggleCc()
  }

  bcc() {
    this.toggleBcc()
  }

  toggleCc() {
    if (this.ccCheckboxTarget.checked) {
      this.ccTarget.classList.remove("hidden")
    } else {
      this.ccInputTarget.value = ''
      this.ccTarget.classList.add("hidden")
    }
  }

  toggleBcc() {
    if (this.bccCheckboxTarget.checked) {
      this.bccTarget.classList.remove("hidden")
    } else {
      this.bccInputTarget.value = ''
      this.bccTarget.classList.add("hidden")
    }
  }
}
