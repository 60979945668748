import { Controller } from "@hotwired/stimulus";
import { create, get } from "@github/webauthn-json";

// Connects to data-controller="webauthn"
export default class extends Controller {
  static targets = [ "publicKey" ]

  connect() {
    this.options = JSON.parse(this.element.getAttribute("data-create-options"))
  }

  createWebauthn(event) {
    event.preventDefault();

    create({ publicKey: this.options })
    .then((arg) => {
      this.publicKeyTarget.value = JSON.stringify(arg);

      this.element.requestSubmit();
    })
    .catch((result) => {
      console.log(result)
    })
  }

  getWebauthn(event) {
    event.preventDefault();

    get({ publicKey: this.options })
    .then((arg) => {
      this.publicKeyTarget.value = JSON.stringify(arg);

      this.element.requestSubmit();
    })
    .catch((result) => {
      console.log(result)
    })
  }
}