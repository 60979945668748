window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = [
    "application/pdf",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "text/csv"
  ];
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert("We don't support the attachment of that type of file.");
  }

  const maxFileSize = 1024 * 1024; // 1MB
  if (event.file.size > maxFileSize) {
    event.preventDefault();
    alert("Only support attachments up to 1MB in size.");
  }
});
