import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="stripe"
export default class extends Controller {
  static targets = [ 'paymentElement', 'paymentMessage', 'form', 'loader', 'submit', 'buttonSpinner', 'buttonText' ]

  connect() {
    this.stripe = null;
    this.elements = null;

    this.init();
    this.checkStatus();
  }

  async init() {
    this.stripe = Stripe(this.data.get('key'));
    const clientSecret = this.data.get('secret');

    const appearance = {
      theme: 'stripe',
      variables: {
        colorDanger: '#ef4444',
        colorPrimary: '#0891b2',
        colorText: '#374151',
        fontSizeXs: '14px',
        fontSize2Xs: '14px',
        fontSize3Xs: '14px',
        fontSizeSm: '14px',
        fontSizeLg: '14px',
        fontSizeXl: '14px',
      },
    };

    this.elements = this.stripe.elements({ appearance, clientSecret });

    const paymentElement = this.elements.create("payment");

    var that = this;

    paymentElement.on("ready", function(){
      that.hideLoader();
    })

    paymentElement.mount(this.paymentElementTarget);
  }

  async submitForm(event) {
    event.preventDefault()

    this.setLoading(true);

    var elements = this.elements;

    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this.data.get('return'),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else {
      this.showMessage("An unexpected error occured.");
    }

    this.setLoading(false);
  }

  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.showMessage("Payment succeeded!");
        break;
      case "processing":
        this.showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        this.showMessage("Your payment was not successful, please try again.");
        break;
      default:
        this.showMessage("Something went wrong.");
        break;
    }
  }

  hideLoader() {
    this.loaderTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }

  showMessage(messageText) {
    const messageContainer = this.paymentMessageTarget;

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageText.textContent = "";
    }, 5000);
  }

  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitTarget.disabled = true;
      this.buttonSpinnerTarget.classList.remove("hidden");
      this.buttonTextTarget.classList.add("hidden");
    } else {
      this.submitTarget.disabled = false;
      this.buttonSpinnerTarget.classList.add("hidden");
      this.buttonTextTarget.classList.remove("hidden");
    }
  }
}
