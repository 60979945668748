// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "alpine-turbo-drive-adapter"
import Alpine from "alpinejs"
import Trix from "trix"
import "@rails/actiontext"
import "./trix-editor-overrides"

window.Alpine = Alpine

import "./controllers"

Alpine.data('colorPicker', () => ({
  init() {
    this.$watch('currentColor', () => setTimeout(() => { this.$refs.form.requestSubmit(), 100 }))
  },
  colors: ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'],
  variants: [200, 300, 400, 500, 600, 700],
  currentColor: '',
  isOpen: false,
  selectColor (color, variant) {
    var tagId = this.$refs.button.getAttribute('data-button-id')
    var spans = document.querySelectorAll(`button[data-button-id="${tagId}"] span`)

    spans.forEach((span) => {
      for (let i = span.classList.length - 1; i >= 0; i--) {
        const className = span.classList[i];
        if (className.startsWith('bg-')) {
          span.classList.remove(className);
          span.classList.add('bg-' + color + '-' + variant);
        }
      }
    });

    this.currentColor = 'bg-' + color + '-' + variant
    this.isOpen = false
  }
}))

Alpine.start()

Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false
