import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select'

// Connects to data-controller="select"
export default class extends Controller {
  initialize() {
    this.select = null
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      searchText: 'Nothing found',
      searchPlaceholder: this.element.getAttribute("data-searchPlaceholder"),
      searchingText: 'Searching...',
      placeholder: this.element.getAttribute("data-placeholder"),
      ajax: function (search, callback) {
        if (search.length < 2) {
          callback("Search must be at least 2 characters")
          return
        }
        fetch(`/forwarders.json?q[email_or_description_cont]=${search}`)
          .then(response => response.json())
          .then(forwarders => {
            let data = []
            for (let i = 0; i < forwarders.length; i++) {
              data.push({value: forwarders[i].id, text: forwarders[i].email})
            }
            callback(data)
          })
          .catch(e => callback(false))
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}
