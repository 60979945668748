import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ["button", "panel"];

  toggle(e) {
    e.preventDefault()

    this.panelTarget.classList.toggle("hidden")

    if (this.panelTarget.classList.contains("hidden")) {
      this.buttonTarget.innerText = "Show advanced settings"
    } else {
      this.buttonTarget.innerText = "Hide advanced settings"
    }
  }
}
