import { Controller } from "@hotwired/stimulus";
import { faker } from "@faker-js/faker";

// Connects to data-controller="random"
export default class extends Controller {
  static targets = ["output", "name"];

  alphanumeric(event) {
    event.preventDefault();

    var string = faker.string.alphanumeric({
      casing: "lower",
      length: { min: 5, max: 12 },
    });
    this.outputTarget.value = string;
    this.nameTarget.value = "";
  }

  memorable(event) {
    event.preventDefault();

    var parts = [faker.word.adjective(), faker.word.noun()];

    if (Math.random() < 0.5) {
      parts.push(faker.number.int({ min: 0, max: 999 }));
    }

    var string = parts.join(this.delimiter());
    string = string.toLowerCase().replace(" ", "-");

    this.outputTarget.value = string;
    this.nameTarget.value = "";
  }

  name(event) {
    event.preventDefault();

    var parts = [faker.person.firstName(), faker.person.lastName()];
    var partsWithSuffix = [...parts]; // Clone the parts array

    if (Math.random() < 0.5) {
      partsWithSuffix.push(faker.number.int({ min: 0, max: 999 }));
    }

    var string = partsWithSuffix.join(this.delimiter());
    string = string.toLowerCase().replace(" ", "-");

    this.outputTarget.value = string;
    this.nameTarget.value = parts.join(" ");
  }

  delimiter() {
    var delimiters = ["", "-", "."];
    return delimiters[Math.floor(Math.random() * delimiters.length)];
  }
}
