import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js"

// Connects to data-controller="tippy"
export default class extends Controller {
  connect() {
    tippy(this.element, {
      content: this.element.getAttribute("data-text"),
      animation: 'scale-subtle',
      theme: 'custom'
    });
  }
}
