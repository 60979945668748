import { Controller } from "@hotwired/stimulus";

// https://gist.github.com/tabishiqbal/56b01bc4bb95eb198ae94b1a743a47fd

export default class extends Controller {
  static targets = [
    "input",
    "outerInput",
    "container",
    "tags",
    "data",
    "suggestions",
  ];

  initialize() {
    this.fetchTags();

    // If we click outside the tag element we need to unfocus it
    document.addEventListener("click", (e) => {
      if (
        !this.containerTarget.contains(e.target) &&
        !this.suggestionsTarget.contains(e.target)
      ) {
        // Outside tags input has been clicked, but not the suggestions

        // If tag input has tag when unfocused make it a pill
        if (this.inputTarget.value.length > 0) {
          this.setTag(this.inputTarget.value);
        }
        this.containerTarget.classList.remove(
          "ring-cyan-500",
          "border-cyan-500",
          "ring-1"
        );
      } else {
        // Inside of tags input has been clicked
        this.containerTarget.classList.add(
          "ring-cyan-500",
          "border-cyan-500",
          "ring-1"
        );
      }
    });

    document.addEventListener("focusin", (e) => {
      if (this.inputTarget == e.target) {
        this.containerTarget.classList.add(
          "ring-cyan-500",
          "border-cyan-500",
          "ring-1"
        );
      }
    });

    document.addEventListener("focusout", (e) => {
      setTimeout(() => {
        if (
          this.inputTarget == e.target &&
          !this.suggestionsTarget.contains(document.activeElement)
        ) {
          // If tag input has tag when unfocused make it a pill
          if (this.inputTarget.value.length > 0) {
            this.setTag(this.inputTarget.value);
          }
          this.containerTarget.classList.remove(
            "ring-cyan-500",
            "border-cyan-500",
            "ring-1"
          );
          this.suggestionsTarget.innerHTML = ""; // Clear suggestions
        }
      }, 100);
    });

    // Add keypress listener
    this.inputTarget.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent form submission
        event.stopPropagation(); // Stop the event from bubbling up
        if (this.inputTarget.value.trim().length > 0) {
          this.setTag(this.inputTarget.value); // Convert current text to a tag
        }
      }
    });
  }

  connect() {
    this.renderTags();
  }

  fetchTags() {
    fetch("/tags.json")
      .then((response) => response.json())
      .then((data) => {
        this.allTags = data.map((tag) => tag.name);
      });
  }

  active() {
    this.inputTarget.focus();
  }

  inputTag(event) {
    const query = event.target.value.trim().toLowerCase();
    this.showSuggestions(query);

    if (event.code === "Space" || event.code === "Comma") {
      const trimmedValue = this.inputTarget.value.trim();
      if (trimmedValue) {
        this.tags = trimmedValue;
        this.inputTarget.value = "";
        this.renderTags();
      } else {
        this.inputTarget.value = ""; // Clear the field if only whitespace was entered
      }
    }
  }

  showSuggestions(query) {
    if (query.length === 0) {
      this.suggestionsTarget.innerHTML = "";
      return;
    }

    const lowerCaseQuery = query.toLowerCase();
    const currentTags = this.tags.map((tag) => tag.toLowerCase());
    const matches = this.allTags.filter(
      (tag) =>
        tag.toLowerCase().includes(lowerCaseQuery) &&
        !currentTags.includes(tag.toLowerCase())
    );

    this.suggestionsTarget.innerHTML = matches
      .map(
        (tag) =>
          `<li data-action="click->tags#selectSuggestion" class="cursor-pointer block truncate px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">${tag}</li>`
      )
      .join("");
  }

  selectSuggestion(event) {
    const tag = event.target.textContent;
    this.setTag(tag);
    this.inputTarget.value = "";
    this.suggestionsTarget.innerHTML = "";
  }

  setTag(tag) {
    this.tags = tag;
    this.inputTarget.value = "";
    this.renderTags();
  }

  backspaceTag(event) {
    if (event.code === "Backspace" && this.inputTarget.value === "") {
      this.removeLastTag();
    }
  }

  renderTags() {
    // get template, and render
    fetch("/tags/template.html")
      .then((response) => response.text())
      .then((html) => {
        let template = document.createElement("template");
        template.innerHTML = html;
        this.tagsTarget.innerHTML = "";
        this.tags.forEach((tag) => {
          let tagElem = template.content.cloneNode(true);
          let tagText = tagElem.querySelector("p");
          tagText.textContent = tag;
          this.tagsTarget.appendChild(tagElem);
        });
      });
  }

  removeTag(event) {
    // console.log(event.currentTarget.parentElement);
    let tag = event.currentTarget.parentElement.querySelector("p").textContent;

    if (tag !== null || tag !== undefined) {
      let tags = this.tags;
      let tagIndex = tags.indexOf(tag);
      tags.splice(tagIndex, 1);
      this.updateTags = tags;
      this.renderTags();
    }
  }

  removeLastTag() {
    let tags = this.tags;
    tags.splice(tags.length - 1, 1);
    this.updateTags = tags;
    this.renderTags();
  }

  set updateTags(tags) {
    this.data.set("tagCollection", JSON.stringify(tags));
    this.dataTarget.value = tags;
  }

  set tags(value) {
    let inputTag = value.trim().replace(/,/g, ""); // Clean input tag
    let tags = this.tags; // Get current tags
    let lowerInputTag = inputTag.toLowerCase(); // Convert input to lowercase for comparison

    // Check if the lowercase version of the input tag already exists
    if (!tags.map((tag) => tag.toLowerCase()).includes(lowerInputTag)) {
      tags.push(inputTag); // Add the original input tag
      this.data.set("tagCollection", JSON.stringify(tags)); // Save tags in original case
      this.dataTarget.value = tags.join(", ");
    }
  }

  get tags() {
    let storedTags = this.data.get("tagCollection");
    return storedTags ? JSON.parse(storedTags) : [];
  }
}
