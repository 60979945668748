import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ["option", "checkout"];

  initialize() {
    this.index = 0
    this.showCurrentButton()
  }

  select() {
    this.optionTargets.forEach((el, i) => {
      if (event.target == el) {
        this.index = i
        this.showCurrentButton()
      }
    })
  }

  showCurrentButton() {
    this.checkoutTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.remove("hidden")
      } else {
        el.classList.add("hidden")
      }
    })
  }
}
