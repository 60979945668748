import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="2fa"
export default class extends Controller {
  download() {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([this.data.get("codes")], { type: "text/plain" })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", "vend-email-backup-codes.txt");

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}
